import React from 'react';
import { graphql } from 'gatsby'
import Container from "react-bootstrap/Container";
import { Helmet } from 'react-helmet';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Stack from 'react-bootstrap/Stack';
import ModuleTitle from '@components/modules/Title'; 
import Breadcrumbs from '@components/breadcrumbs';
import { StaticImage } from "gatsby-plugin-image";
import { cleanP } from '@components/utils/CleanedHtml';
import Lightbox from '@components/lightbox';
import ImageTransform from "@components/common/ggfx-client/module/components/image-transform";
import { fadeInFromTop, fadeInFromBottom } from '@components/utils/animations';
import getVideoId from 'get-video-id';
import "./image.scss";

const BannerVideo = ({ imagetransforms, pageId, className, breadcrumbs, breadcrumbsClass, video, afterTitle }) => {
    const {
        BannerImage
    } = video;

    const hasVideo = BannerImage[0]?.VideoUrl ? BannerImage[0].VideoUrl : false;
    const video_id = BannerImage[0]?.VideoUrl ? getVideoId(BannerImage[0]?.VideoUrl) : ""
    // GGFX
    let processedImages = JSON.stringify({});
    if (imagetransforms?.BannerImage?.videoBannerImage_Transforms) {
        processedImages = imagetransforms.BannerImage.videoBannerImage_Transforms;
    }

    return (
        // <InView {...inViewOptions}>
        //     {({ ref, inView }) => (
            <>
            <Helmet>
                <link data-react-helmet="true" rel="alternate" href="https://www.exclusive-links.com/" hrefLang="en" />
            </Helmet>
                <section
                    className={`section video-banner${className ? ' ' + className : ''}`}
                    // ref={ref}
                    // initial="hidden"
                    // animate={inView ? "visible" : "hidden"}
                    // variants={containerVariants}
                >
                    <StaticImage
                        src="../../../images/pattern.jpg"
                        quality={50}
                        formats={["auto", "webp"]}
                        alt="Background Image - Exclusive Links"
                        className="pattern-img"
                    />
                    <Container fluid="lg">
                        <Row>
                            <Col xs={12}>
                                <Stack className="gap-16 gap-md-24 gap-lg-16 image-banner__content" variants={fadeInFromTop}>
                                    {!BannerImage[0]?.hideBreadcrumbs &&
                                        <Breadcrumbs
                                            breadcrumbs={breadcrumbs}
                                            breadcrumbsClass={breadcrumbsClass}
                                        />
                                    }
                                    {BannerImage[0]?.ItemTitle &&
                                        <ModuleTitle
                                            title={BannerImage[0].ItemTitle}
                                            className="h6 text-white image-banner__title" 
                                            as="h1"
                                        />
                                    }
                                    {afterTitle &&
                                        <div className="image-banner__after-title">{afterTitle}</div>
                                    }
                                </Stack>
                            </Col>
                            <Col xs={12} className="image-banner__wrapper__image">
                                <div className="image-banner__wrapper__image__div" variants={fadeInFromBottom}>
                                    {hasVideo &&
                                        <>
                                            <Lightbox videoUrl={[`${hasVideo}`]} lightboxType="youtube" className="image-banner__lightbox">
                                                    <img
                                                    src={"http://img.youtube.com/vi/"+video_id?.id+"/hqdefault.jpg"}
                                                    // quality={100}
                                                    // formats={["auto", "webp"]}
                                                    alt={`${BannerImage[0]?.videoBannerImage?.alternativeText ? BannerImage[0]?.videoBannerImage?.alternativeText + " - Exclusive Links" : cleanP(BannerImage[0]?.itemTitle) + " - Exclusive Links"}`}
                                                    className="image-banner__image"
                                                />
                                                
                                            </Lightbox>
                                        </>
                                    }
                                    {!hasVideo &&
                                        <>
                                            {BannerImage[0]?.videoBannerImage?.url ?
                                                <ImageTransform
                                                    imagesources={BannerImage[0].videoBannerImage.url}
                                                    renderer="srcSet"
                                                    imagename="videos.BannerImage.floatImage"
                                                    attr={{ alt: `${BannerImage[0]?.videoBannerImage?.alternativeText ? BannerImage[0]?.videoBannerImage?.alternativeText : cleanP(BannerImage[0]?.itemTitle)}`, className: "image-banner__image" }}
                                                    imagetransformresult={processedImages}
                                                    id={pageId}
                                                />
                                            :
                                                <StaticImage
                                                    src="../../../images/no-image.png"
                                                    width={560}
                                                    height={600}
                                                    formats={["auto", "webp"]}
                                                    alt=""
                                                    className="image-banner__image"
                                                />
                                            }
                                        </>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                </>
        //     )}
        // </InView>
    )
}

export default BannerVideo

export const videoBannerFragment = graphql`
    fragment VideoBannerFragment on GLSTRAPI_ComponentModulesBannerImage {
        id
        ItemText
        ItemTitle
        showGoogleRating
        hideBreadcrumbs
        VideoUrl
        videoBannerImage: Image {
            alternativeText
            url
        }
    }
`